import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>I'm a Full Stack Wordpress Developer</h3>
                            <div className="separator" />
                            <p>I have worked on a multitude of web based projects for a range of clients providing Development ( WordPress, WooCommerce, Bootstrap, Responsive Layouts, PHP, HTML, CSS, SCSS, JS, jQuery ) and eCommerce Solutions. I have a keen understanding of wireframes, sitemaps and user experience design which I put to good use on every project I work on.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/anna-malinowska-23280198/')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Wordpress" value={90} delay={1100} />
                                <Progress name="PHP" value={70} delay={1100} />
                                {/* <Progress name="React" value={40} delay={1100} /> */}
                                <Progress name="HTML5" value={90} delay={1100} />
                                <Progress name="MSQL" value={70} delay={1100} />
                                <Progress name="CSS3" value={90} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": false,
                                "value_area": 70
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .4
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero